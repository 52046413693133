@import url('https://fonts.googleapis.com/css2?family=Sen:wght@400;800&display=swap');
$media-desktop: 'screen and (min-width : 1024px)';
$media-tablet: 'screen and (min-width : 768px)';
$media-mobile: 'screen and (min-width : 600px)';
$media-mobile-sm: 'screen and (min-width : 480px)';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  font-family: 'Sen';
}

.title {
  font-weight: 800;
  line-height: 48px;
  letter-spacing: 0.05em;
  margin-bottom: 0.3em;
}

.home {
  padding: 4em;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-content {
  margin: auto 0;
}

.download > img {
  visibility: hidden;
}

.icon {
  width: 50px;
  height: 50px;
}

.icon:hover {
  cursor: pointer;
}

.title-container {
  background-clip: text;
  -webkit-background-clip: text;
  color: black;
  background-repeat: no-repeat;
  background-size: cover;
}

.title-container > h1 {
  font-family: 'Sen';
  font-style: normal;
  font-weight: 800;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
  font-size: 4em;
  @media #{$media-desktop} {
    font-size: 6em;
  }
}

.description {
  font-family: 'Sen', 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: 400;
  font-size: 1.5em;
  line-height: 36px;
}

.download {
  display: flex;
  margin-top: 3em;
}

.download > img:hover {
  box-shadow: 5px 5px 2px 1px silver;
  cursor: pointer;
}

.home img:nth-child(2) {
  margin-left: 2.5em;
}

.about {
  min-height: 100vh;
  background-image: url('images/background.jpeg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 4em;
}

.about-title {
  font-family: 'Sen';
  font-style: normal;
  font-weight: 800;
  font-size: 40px;
  line-height: 48px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
}

.about-bg {
  position: absolute;
}

.about-title {
  font-style: normal;
  display: flex;
  align-items: center;
  font-size: 40px;
}

.about-description {
  width: 70vw;
  letter-spacing: 0.05em;
  line-height: 24px;
  overflow-wrap: break-word;
  @media #{$media-tablet} {
    width: 40vw;
  }
}

.earn {
  padding: 4em;
  display: flex;
  flex-direction: column;
  gap: 5em;
  min-height: 100vh;
  @media #{$media-desktop} {
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 4em 12em;
  }
}
.earn-title {
  font-size: 30px;
}

.earn-content {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  line-height: 19px;
  min-height: 80vh;
  @media #{$media-desktop} {
    padding: 5em;
  }
}

$phone-width: 30em;
.phone {
  width: $phone-width;
  margin-left: calc($phone-width / -2.3);
  @media #{$media-desktop} {
    width: 40em;
    height: 45em;
    margin-left: calc($phone-width / -3.3);
  }
}

.contact-us {
  margin-top: auto;
  display: flex;
  flex-direction: column;
}

form {
  display: flex;
}

form input {
  padding-left: 5px;
  background: #e9e9e9;
  border-radius: 5px;
  border: none;
  width: 300px;
  height: 50px;
  margin-top: 10px;
}

form button {
  margin-left: -30px;
  margin-top: 10px;
  background: none;
  border: none;
  &:hover {
    cursor: pointer;
  }
}

.email-btn:hover {
  opacity: 0.6;
}

footer {
  background-color: black;
  color: white;
  display: flex;
  justify-content: right;
  gap: 2em;
  padding-right: 1em;
  & > a {
    text-decoration: none;
    color: inherit;
    &:hover {
      text-decoration: underline;
    }
  }
}
